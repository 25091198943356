import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SalesInvoice } from '../types/training.type';
import { APP_CONFIG_TOKEN, AppConfig } from '@alimento-ipv-frontend/ui-lib';
import { SimplePerson } from '../types/person.type';

@Injectable({
  providedIn: 'root'
})
export class SalesInvoiceService {
  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getSalesInvoice(saleInvoiceId: string): Observable<SalesInvoice> {
    return this.http.get<SalesInvoice>(`${this.config.readApiUrl}/salesinvoices/${saleInvoiceId}`);
  }

  updateSalesInvoice(salesInvoice: SalesInvoice): Observable<any> {
    return this.http.put<any>(`${this.config.writeApiUrl}/salesinvoices/${salesInvoice.id}`, salesInvoice);
  }

  approve(salesInvoiceId: string): Observable<any> {
    return this.http.put<any>(`${this.config.writeApiUrl}/salesinvoices/${salesInvoiceId}/approve`, {});
  }

  getSalesInvoicePersons(trainingId: string): Observable<SimplePerson[]> {
    //todo uncomment als backend klaar is
    return of([]);
    // return this.http.get<SimpleBranch[]>(`${this.config.readApiUrl}/trainings/${trainingId}/persons`, {
    //   params: {
    //     noSalesInvoices: true
    //   }
    // });
  }

  createSalesInvoices(trainingId: string, personIds: string[]): Observable<void> {
    return this.http.post<void>(`${this.config.writeApiUrl}/salesinvoices`, {
      trainingId: trainingId,
      personIds: personIds
    });
  }
}
