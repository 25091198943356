<p-dialog
  *ngIf='popupVisible'
  (blur)='popupVisible = false'
  (onHide)='closePopup()'
  [(visible)]='popupVisible'
  [closable]='false'
  [draggable]='false'
  [modal]='true'
  [resizable]='false'
  [style]="{ width: '60rem' }"
  appendTo='body'
  header="{{(participant?.id ? 'enrollments.editParticipant' : 'enrollments.addParticipant') | translate}}"
>
  <div *ngIf='participant' [formGroup]='formGroup'>
    <h2 class="text-xl text-text font-bold my-4">{{'enrollments.participant' | translate}}</h2>

    <div class='flex flex-col gap-2' *ngIf='showSearch; else newPersonTemplate'>
      <div class='flex flex-col gap-2'>
        <p-radioButton
          [formControl]='personFormControl'
          id='existing'
          label="{{ 'enrollments.participants.existingPerson' | translate }}"
          name='personMatch'
          value='existing'
        ></p-radioButton>
        <div *ngIf='personFormControl.value === "existing"'>
          <alimento-ipv-frontend-person-select
            [formControl]="searchPersonFormControl"
            [includeEmployments]='true'
            [showEmployments]='true'
          ></alimento-ipv-frontend-person-select>
        </div>
      </div>

      <div class='flex flex-col gap-2'>
        <p-radioButton
          [formControl]='personFormControl'
          id='new'
          label="{{ 'enrollments.participants.newPerson' | translate }}"
          name='personMatch'
          value='new'
        ></p-radioButton>
        <div *ngIf='personFormControl.value === "new"'>
          <div class='conditional-fields'>
            <alimento-ipv-frontend-person [dataOptional]='dataOptional'
                                          [disableUniqueValidation]='disableUniquePersonValidation'
                                          formControlName='person'
                                          name="person"
            ></alimento-ipv-frontend-person>
          </div>
        </div>
      </div>
    </div>

    <ng-template #newPersonTemplate>
      <alimento-ipv-frontend-person [dataOptional]='dataOptional'
                                    [disableUniqueValidation]='disableUniquePersonValidation'
                                    formControlName='person'
                                    name="person"
      ></alimento-ipv-frontend-person>
    </ng-template>

    <div class='mt-4'>
      <h2 class="text-xl text-text font-bold my-4">{{'enrollments.professionalPersonData' | translate}}</h2>
      <alimento-ipv-frontend-form-field *ngIf='groupId || !dataOptional'
                                        [formGroup]='formGroup'
                                        [required]='!dataOptional'
                                        formField='employer'
                                        fieldKey='branches.branch'
      >
        <alimento-ipv-frontend-branch-select formControlName='employer'
                                             [extraFilters]='groupIdFilter'
        ></alimento-ipv-frontend-branch-select>

      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        formField='function'
        fieldKey='enrollments.function'
      >
        <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('function')?.value}}</span>
        <div *ngIf='formGroup.enabled'>
          <input class="w-full" formControlName="function" id="function" pInputText type="text" />
        </div>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [required]='!dataOptional'
        formField='workStatusId'
        fieldKey='enrollments.workStatus'
      >
        <span *ngIf='formGroup.disabled' class='read-only-field'>{{workStatusReadOnly}}</span>
        <div *ngIf='formGroup.enabled'>
          <p-dropdown
            *ngIf="workStatus$ | async as workStatus"
            [filter]="true"
            [options]="workStatus"
            appendTo="body"
            formControlName="workStatusId"
            id="workStatusId"
            optionLabel="label"
            optionValue="data"
            placeholder="{{ 'enrollments.chooseOption' | translate }}"
            styleClass="w-full"
            [showClear]="true"
          ></p-dropdown>
        </div>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field *ngIf='showEmploymentStartDate'
                                        [formGroup]='formGroup'
                                        formField='employmentStartDate'
                                        fieldKey='enrollments.employmentStartDate'
      >
        <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('employmentStartDate')?.value | date:'dd/MM/yyyy'}}</span>
        <div *ngIf='formGroup.enabled'>
          <lib-date-picker formControlName='employmentStartDate'></lib-date-picker>
        </div>
      </alimento-ipv-frontend-form-field>

      <div class='conditional-fields' [hidden]='!showInterimOfficeFields'>
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [required]='showInterimOfficeFields'
          formField='interimOfficeId'
          fieldKey='enrollments.interimOffice'
        >
          <span *ngIf='formGroup.disabled' class='read-only-field'>{{interimOfficeReadOnly}}</span>
          <div *ngIf='formGroup.enabled' class='flex flex-col gap-2'>
            <p-radioButton id='newInterimOffice_false' label="{{ 'enrollments.existingInterimOffice' | translate }}" formControlName='newInterimOffice' name='newInterimOffice' [value]='false'
            ></p-radioButton>
            <div *ngIf="formGroup.get('newInterimOffice').value === false">
              <alimento-ipv-frontend-interim-office-select
                formControlName='interimOfficeId'
              ></alimento-ipv-frontend-interim-office-select>
            </div>

            <p-radioButton id='newInterimOffice_true' label="{{ 'enrollments.newInterimOffice' | translate }}" formControlName='newInterimOffice' name='newInterimOffice' [value]='true'
            ></p-radioButton>
            <div *ngIf="formGroup.get('newInterimOffice').value === true">
              <alimento-ipv-frontend-form-field formField='interimOfficeName'
                                                fieldKey='enrollments.interimOfficeName'
                                                [required]='true'
                                                [formGroup]='formGroup'
              >
                <input class="w-full" formControlName="interimOfficeName" id="interimOfficeName" pInputText type="text" />
              </alimento-ipv-frontend-form-field>
              <div class='max-w-[425px]'>
                <alimento-ipv-frontend-address formControlName='interimOfficeAddress'
                ></alimento-ipv-frontend-address>
              </div>
            </div>
          </div>
        </alimento-ipv-frontend-form-field>

        <div>
          <h2 class="text-xl text-text font-bold my-4">{{ 'enrollments.interimContact' | translate }}</h2>
          <div class='flex gap-4'>
            <div class='basis-1/2'>
              <alimento-ipv-frontend-form-field
                [formGroup]='formGroup'
                formField='interimOfficeContactFirstName'
                fieldKey='enrollments.interimContactFirstName'
              >
                <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('enrollment.interimOfficeContactFirstName')?.value}}</span>
                <div *ngIf='formGroup.enabled'>
                  <input class="w-full" formControlName="interimOfficeContactFirstName" id="enrollment.interimOfficeContactFirstName" pInputText type="text" />
                </div>
              </alimento-ipv-frontend-form-field>
            </div>
            <div class='basis-1/2'>
              <alimento-ipv-frontend-form-field
                [formGroup]='formGroup'
                formField='interimOfficeContactLastName'
                fieldKey='enrollments.interimContactLastName'
              >
                <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('enrollment.interimOfficeContactLastName')?.value}}</span>
                <div *ngIf='formGroup.enabled'>
                  <input class="w-full" formControlName="interimOfficeContactLastName" id="enrollment.interimOfficeContactLastName" pInputText type="text" />
                </div>
              </alimento-ipv-frontend-form-field>
            </div>
          </div>

          <alimento-ipv-frontend-form-field
            [formGroup]='formGroup'
            [errorTemplate]='emailErrorTemplate'
            formField='interimOfficeContactEmail'
            fieldKey='enrollments.interimContactEmail'
          >
            <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('enrollment.interimOfficeContactEmail')?.value}}</span>
            <div *ngIf='formGroup.enabled'>
              <input class="w-full" formControlName="interimOfficeContactEmail" id="enrollment.interimOfficeContactEmail" pInputText type="text" />
            </div>

            <ng-template #emailErrorTemplate>
              <small *ngIf="formGroup.get('interimOfficeContactEmail')?.errors?.['email']">
                {{ 'validation.invalidEmail' | translate }}
              </small>
            </ng-template>
          </alimento-ipv-frontend-form-field>
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate='footer'>
    <div class='flex'>
      <p-button
        *ngIf='participant'
        [loading]='loading'
        (onClick)='addOrUpdateForm()'
        icon='pi pi-save'
        iconPos='left'
        label="{{ (participant?.id ? 'enrollments.editParticipant' : 'enrollments.addParticipant') | translate }}"
      ></p-button>
      <p-button
        (onClick)='closePopup()'
        icon='pi pi-times'
        iconPos='left'
        label="{{ 'persons.transitions.actions.cancel' | translate }}"
        styleClass='inverted-button'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
