import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
  Signal,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MyMessageService } from '@alimento-ipv-frontend/ui-lib';
import {
  PurchaseEntry,
  PurchaseEntryCreateEvent,
  SalesEntryApproveEvent,
  SalesInvoiceUpdateEvent,
  SearchSalesInvoice
} from '../../../types/training.type';
import { PurchaseEntryService } from '../../../services/purchase-entries.service';
import { first } from 'rxjs';
import { SimpleBranch } from '../../../types/branch.type';
import {
  PurchaseEntryCreateDialogComponent
} from '../purchase-entry-create-dialog/purchase-entry-create-dialog.component';
import { SessionService } from '../../../services/session.service';
import { SalesInvoiceService } from '../../../services/sales-invoice.service';
import { SALES_INVOICE_ACTION } from '../../../types/training.enum';
import {
  ApproveSalesInvoiceDialogComponent
} from '../approve-sales-invoice-dialog/approve-sales-invoice-dialog.component';
import { SalesInvoicePopupComponent } from '../sales-invoice-popup/sales-invoice-popup.component';
import { SimplePerson } from '../../../types/person.type';

@Component({
  selector: 'alimento-ipv-frontend-invoices-overview',
  templateUrl: './invoices-overview.component.html'
})
export class InvoicesOverviewComponent implements OnChanges {
  @Input()
  trainingId: string;

  @Input()
  readOnly: boolean;

  @Input()
  hasCounselor: boolean;

  @Input()
  purchaseEntries: Signal<PurchaseEntry[]> = signal([]);

  @Input()
  salesInvoices: Signal<SearchSalesInvoice[]> = signal([]);

  @Output()
  purchaseEntryChanges: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  salesInvoiceChanges: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(ApproveSalesInvoiceDialogComponent)
  approveSalesInvoiceDialog: ApproveSalesInvoiceDialogComponent;

  @ViewChild(SalesInvoicePopupComponent)
  salesInvoiceDialog: SalesInvoicePopupComponent;

  branches: SimpleBranch[] = [];
  showCreatePurchaseEntriesButton = false;
  persons: SimplePerson[] = []
  showCreateSalesInvoicesButton = false;
  addSalesInvoiceLoading = false;
  currentSalesInvoice: SearchSalesInvoice;
  currentSalesInvoiceAction: SALES_INVOICE_ACTION;
  selectedIndex = 0;

  constructor(private messageService: MyMessageService,
              private sessionService: SessionService,
              private purchaseEntryService: PurchaseEntryService,
              private salesInvoiceService: SalesInvoiceService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trainingId']?.currentValue) {
      this._getPurchaseEntryBranches();
      this._getSalesInvoicePersons();
    }

    this._setShowPurchaseEntryButton();
    this._setShowSalesInvoicesButton();
  }

  createPurchaseEntries(createDialog: PurchaseEntryCreateDialogComponent): void {
    createDialog.open();
  }

  executeCreatePurchaseEntries(event: PurchaseEntryCreateEvent, createDialog: PurchaseEntryCreateDialogComponent): void {
    event.setLoading(true);
    this.purchaseEntryService.createPurchaseEntries(this.trainingId, event.branchIds).pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success("trainings.purchaseEntriesCreated");
          event.setLoading(false);
          createDialog.closeDialog();
          this._getPurchaseEntryBranches();
          this.purchaseEntryChanges.emit();
        },
        error: () => {
          event.setLoading(false);
          this._getPurchaseEntryBranches();
          createDialog.closeDialog();
          this.purchaseEntryChanges.emit();
        }
      });
  }

  createSalesInvoices(): void {
    this.addSalesInvoiceLoading = true;
    this.salesInvoiceService.createSalesInvoices(this.trainingId, this.persons.map(person => person.id))
      .pipe(first())
      .subscribe({
        next: () => {
          this.addSalesInvoiceLoading = false;
          this.messageService.success();
          this._getSalesInvoicePersons();
          this.salesInvoiceChanges.emit();
        },
        error: () => {
          this.addSalesInvoiceLoading = false;
          this._getSalesInvoicePersons();
          this.salesInvoiceChanges.emit();
        }
      });
  }

  executeApproveSalesInvoice(event: SalesEntryApproveEvent): void {
    event.setLoading(true);
    this.salesInvoiceService.approve(event.salesInvoice.id).pipe(first())
      .subscribe({
        next: () => {
          this.salesInvoiceChanges.emit();
          this.messageService.success();
          event.setLoading(false);
          this.approveSalesInvoiceDialog.closeDialog();
          this.salesInvoiceDialog.closePopup();
        },
        error: () => event.setLoading(false)
      });
  }

  private _getPurchaseEntryBranches(): void {
    this.showCreatePurchaseEntriesButton = false;
    this.purchaseEntryService.getPurchaseEntryBranches(this.trainingId).pipe(first())
      .subscribe(branches => {
        this.branches = branches;
        this._setShowPurchaseEntryButton();
      });
  }

  private _setShowPurchaseEntryButton(): void {
    this.showCreatePurchaseEntriesButton = !this.readOnly &&
      this.branches?.length > 0 &&
      this.sessionService.nrOfSessions() > 0 &&
      this.hasCounselor;
  }

  private _getSalesInvoicePersons(): void {
    this.showCreatePurchaseEntriesButton = false;
    this.salesInvoiceService.getSalesInvoicePersons(this.trainingId).pipe(first())
      .subscribe(persons => {
        this.persons = persons;
        this._setShowSalesInvoicesButton();
      });
  }

  private _setShowSalesInvoicesButton(): void {
    this.showCreateSalesInvoicesButton = !this.readOnly &&
      this.persons?.length > 0 &&
      this.sessionService.nrOfSessions() > 0;
  }

  onSaleInvoiceActionClicked(index: number, salesInvoice: SearchSalesInvoice, action: SALES_INVOICE_ACTION): void {
    this.selectedIndex = index;
    this.currentSalesInvoiceAction = action;
    this.currentSalesInvoice = salesInvoice;
    if (action === SALES_INVOICE_ACTION.approve) {
      this.approveSalesInvoiceDialog.open();
    }
    else {
      this.salesInvoiceService.getSalesInvoice(salesInvoice.id).pipe(first())
        .subscribe(salesInvoiceDetail => {
          salesInvoiceDetail.id = salesInvoice.id;
          this.salesInvoiceDialog.openPopup(salesInvoiceDetail, action);
        });
    }
  }

  salesInvoiceUpdate(event: SalesInvoiceUpdateEvent): void {
    event.setLoading(true);
    this.salesInvoiceService.updateSalesInvoice(event.salesInvoice).pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success();
          event.setLoading(false);
          this.salesInvoiceChanges.emit();

          if (event.andApprove) {
            this.executeApproveSalesInvoice({
              setLoading: event.setLoading,
              salesInvoice: {id: event.salesInvoice.id} as SearchSalesInvoice
            });
          }
          else if (event.andNext) {
            this.changeSelectedInvoiceIndex(1);
          }
          else {
            this.salesInvoiceDialog.closePopup();
          }
        },
        error: () => event.setLoading(false)
      })
  }

  changeSelectedInvoiceIndex(event: number): void {
    const selectedIndex = (this.selectedIndex + event) % this.salesInvoices().length;
    this.onSaleInvoiceActionClicked(selectedIndex, this.salesInvoices()[selectedIndex], this.currentSalesInvoiceAction);
  }
}
