<div *ngIf='training()'>
  <alimento-ipv-frontend-enrollment-popup
    #enrollmentsPopupComponent
    [readOnly]='readOnly || viewMode'
    [training]="training()"
    (formSubmit)="onEnrollmentPopupSubmit($event, enrollmentsPopupComponent)"
  ></alimento-ipv-frontend-enrollment-popup>

  <alimento-ipv-frontend-enrollment-request-popup
    #enrollmentRequestsPopupComponent
    [readOnly]='readOnly || viewMode'
    [isOpenExternOrCustom]='isOpenExternOrCustom'
    [training]="training()"
    [groupId]='groupId'
    [enterpriseId]='enterpriseId'
    [maxNewParticipants]='training().maxParticipants ? training().maxParticipants - nrOfParticipants() : undefined'
    (formSubmit)="onEnrollmentRequestPopupSubmit($event, enrollmentRequestsPopupComponent)"
    [reimbursementRequest]='reimbursementRequest'
  ></alimento-ipv-frontend-enrollment-request-popup>

  <alimento-ipv-frontend-cancel-enrollment-dialog
    (cancelEnrollmentConfirm)="cancelEnrollment($event)"
  ></alimento-ipv-frontend-cancel-enrollment-dialog>

  <alimento-ipv-frontend-header title="{{ 'trainings.participantsHeader' | translate }}">
    <div *ngIf="!isOpenExternOrCustom && !readOnly && trainingStatus !== TrainingStatusKey.Cancelled && !training().isCancelled"
         pTooltip="{{trainingStatusIsDraft ? ('error.cannot-enroll-when-training-not-active' | translate) : ''}}">
      <p-button
        (onClick)="addEnrollment(enrollmentsPopupComponent, nrOfParticipants() >= training()?.maxParticipants)"
        [disabled]='trainingStatusIsDraft'
        icon="pi pi-user-plus"
        label="{{ (nrOfParticipants() >= training()?.maxParticipants ? 'enrollments.addReserve' : 'enrollments.addEnrollment') | translate | uppercase }}"
      ></p-button>
    </div>

    <div *ngIf='isOpenExternOrCustom'>
      <alimento-ipv-frontend-enrollment-request-participant-popup #participantPopup
                                                                  [participant]='currentParticipant'
                                                                  [groupId]='groupId'
                                                                  [dataOptional]='true'
                                                                  [disableUniquePersonValidation]='!!currentParticipant?.id'
                                                                  [trainingStartDate]='trainingStartDate'
                                                                  [showSearch]='!currentParticipant?.id'
                                                                  (formSubmit)='submitParticipant($event, participantPopup)'
      ></alimento-ipv-frontend-enrollment-request-participant-popup>

      <p-button
        (onClick)="addParticipant(participantPopup)"
        [disabled]='trainingStatusIsDraft'
        icon="pi pi-user-plus"
        label="{{'enrollments.addParticipant' | translate | uppercase}}"
      ></p-button>
    </div>
  </alimento-ipv-frontend-header>

  <p-button *ngIf='hasRequestInToVerify() && !validationRequest'
            (onClick)='requestToVerify()'
  > {{"enrollments.requestToVerify" | translate | uppercase}}
  </p-button>

  <div *ngIf='validationRequest'
       class='mt-4 p-4 bg-grey-0 border-l-4 border-l-primary flex flex-col items-start'>
    <span>{{'enrollments.validationRequestSend' | translate:{
      date: validationRequest.createdOn | date:"dd/MM/yyyy",
      endDate: validationRequest.expires | date:"dd/MM/yyyy",
      by: validationRequest.createdBy
    } }}</span>
    <div class='flex items-center gap-4'>
      <div (click)='navigator.clipboard.writeText(validationRequest.url)'
           class='flex items-center gap-2 cursor-pointer hover:bg-primary-hover text-primary'>
        <i class='font-material'>content_copy</i> {{'enrollments.copyLink' | translate}}
      </div>
      <div (click)='sendExtraLink()'
           class='flex items-center gap-2 cursor-pointer hover:bg-primary-hover text-primary'>
        <i class='font-material'>mail</i> {{'enrollments.sendLink' | translate}}
      </div>
    </div>
  </div>
  <ng-container *ngIf="enrollments() as enrollments;">
    <alimento-ipv-frontend-enrollments-overview
      *ngIf="enrollments.length || enrollmentRequests()?.length; else noEnrollments"
      [enrollments]="enrollments"
      [enrollmentRequests]='enrollmentRequests()'
      [training]='training'
      [isOpenExternOrCustom]='isOpenExternOrCustom'
      (enrollmentAction)="enrollmentAction($event, enrollmentsPopupComponent)"
      (enrollmentRequestAction)="enrollmentRequestAction($event, enrollmentRequestsPopupComponent)"
      [readOnly]='readOnly'
      [validationRequest]='validationRequest'
    >
    </alimento-ipv-frontend-enrollments-overview>
  </ng-container>

  <ng-template #noEnrollments>
    <div class="no-enrollments">{{ 'trainings.noEnrollments' | translate }}</div>
  </ng-template>
</div>
