import { CanActivateFn, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import {
  ErrorPageComponent, FEATURE,
  FeatureFlagGuard,
  hasRole,
  JwtTokenPageComponent,
  Role
} from '@alimento-ipv-frontend/ui-lib';
import { environment } from '@alimento-ipv-frontend/environments';

export const DevEnvGuard: CanActivateFn = (): boolean => {
  return !environment.production;
};

export const appRoutes: Routes = [
  { path: '', redirectTo: '/trainings', pathMatch: 'full' },
  {
    path: 'trainings',
    canActivate: [MsalGuard, hasRole],
    data: { auth: [Role.CASE_MANAGER_READ, Role.COUNSELOR_READ, Role.ADMIN] },
    loadChildren: () => import('@alimento-ipv-frontend/application-lib').then((m) => m.TrainingsRoutingModule)
  },
  {
    path: 'training-templates',
    canActivate: [MsalGuard, hasRole],
    data: { auth: [Role.CASE_MANAGER_READ, Role.COUNSELOR_READ, Role.ADMIN] },
    loadChildren: () => import('@alimento-ipv-frontend/application-lib').then((m) => m.TrainingTemplatesRoutingModule)
  },
  {
    path: 'branches',
    canActivate: [MsalGuard, hasRole],
    data: { auth: [Role.CASE_MANAGER_READ, Role.COUNSELOR_READ, Role.ADMIN] },
    loadChildren: () => import('@alimento-ipv-frontend/application-lib').then((m) => m.BranchesRoutingModule)
  },
  {
    path: 'enterprises',
    canActivate: [MsalGuard, hasRole],
    data: { auth: [Role.CASE_MANAGER_READ, Role.COUNSELOR_READ, Role.ADMIN] },
    loadChildren: () => import('@alimento-ipv-frontend/application-lib').then((m) => m.EnterprisesRoutingModule)
  },
  {
    path: 'enterprise-groups',
    canActivate: [MsalGuard, hasRole],
    data: { auth: [Role.CASE_MANAGER_READ, Role.COUNSELOR_READ, Role.ADMIN] },
    loadChildren: () => import('@alimento-ipv-frontend/application-lib').then((m) => m.EnterpriseGroupRoutingModule)
  },
  {
    path: 'persons',
    canActivate: [MsalGuard, hasRole],
    data: { auth: [Role.CASE_MANAGER_READ, Role.COUNSELOR_READ, Role.ADMIN] },
    loadChildren: () => import('@alimento-ipv-frontend/application-lib').then((m) => m.PersonsRoutingModule)
  },
  {
    path: 'communications',
    canActivate: [MsalGuard, hasRole],
    data: { auth: [Role.CASE_MANAGER_READ, Role.COUNSELOR_READ, Role.ADMIN] },
    loadChildren: () => import('@alimento-ipv-frontend/application-lib').then((m) => m.CommunicationsRoutingModule)
  },
  {
    path: 'reimbursement-requests',
    canActivate: [MsalGuard, hasRole, FeatureFlagGuard],
    data: { auth: [Role.CASE_MANAGER_READ, Role.COUNSELOR_READ, Role.ADMIN], featureFlag: FEATURE.OPEN_EXTERN_AND_CUSTOM_TRAININGS },
    loadChildren: () => import('@alimento-ipv-frontend/application-lib').then((m) => m.ReimbursementRequestsRoutingModule)
  },
  {
    path: 'training-programs',
    canActivate: [MsalGuard, hasRole, FeatureFlagGuard],
    data: { auth: [Role.CASE_MANAGER_READ, Role.COUNSELOR_READ, Role.ADMIN], featureFlag: FEATURE.TRAINING_PROGRAM },
    loadChildren: () => import('@alimento-ipv-frontend/application-lib').then((m) => m.TrainingProgramRoutingModule)
  },
  { path: 'error', component: ErrorPageComponent, pathMatch: 'full' },
  { path: 'featureNotEnabled', component: ErrorPageComponent, pathMatch: 'full', data: { message: 'error.featureNotEnabled' } },
  { path: 'jwt-token', component: JwtTokenPageComponent, pathMatch: 'full', canActivate: [DevEnvGuard] },
  { path: 'unAuthorized', component: ErrorPageComponent, pathMatch: 'full', data: { message: 'error.unAuthorized' } },
  { path: '**', component: ErrorPageComponent, pathMatch: 'full', data: {message: 'error.pageNotExists'} },
];
