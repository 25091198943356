<div class='mt-4 mx-8'>
  <div class="flex items-center justify-between mb-4">
    <h1 class="uppercase">{{ 'enterpriseGroups.menuTitle' | translate }}</h1>
   <p-button
      label="{{ 'enterpriseGroups.actions.add' | translate }}"
      styleClass="uppercase"
      [routerLink]="['create']"
    ></p-button>
  </div>

  <lib-search [data$]='searchData$'
              [filters]='searchFilters'
              [cardTemplate]='cardTemplate'
              name='enterpriseGroups'
              searchPlaceholderKey='enterpriseGroups.searchPlaceholder'
              availableResultsTranslateKey='enterpriseGroups.menuTitle'
  >
    <ng-template #cardTemplate let-item="item">
      <alimento-ipv-frontend-enterprise-group-card
        [enterpriseGroup]='item'
        [routerLink]='["/enterprise-groups", item.enterpriseGroupId]'
        tabindex="0"
      ></alimento-ipv-frontend-enterprise-group-card>
    </ng-template>
  </lib-search>
</div>
