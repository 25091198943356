<div *ngIf="enterpriseGroup"
     class="shadow-grey-2 shadow-md my-2 p-4 relative border border-input-border"
     [ngClass]='{
     "bg-primary-hover border-primary" : selected,
     "bg-white": !selected,
     "cursor-pointer hover:bg-grey-1": clickable
     }'
>
  <div class='flex items-center justify-between'>
    <div class='flex items-center gap-1'>
      <a class='font-bold text-lg hover:underline' [routerLink]='["/enterprise-groups", enterpriseGroup.enterpriseGroupId]'>
        {{enterpriseGroup.alimentoId}} - {{enterpriseGroup.name}}
      </a>
      <span>({{enterpriseGroup.enterpriseNumber}})</span>
      <!--      <span>{{enterprise.address}}</span>-->
    </div>
  </div>

  <div class='flex items-center justify-between'>
    <div class='flex flex-col gap-1'>
      <span>{{'branches.rszNumber' | translate}}: {{enterpriseGroup.rszNumber}}</span>
      <span>{{'branches.vatNumber' | translate}}: {{enterpriseGroup.vatNumber}}</span>
    </div>

    <div class='flex items-center justify-between'>
      <span>{{enterpriseGroup.employees}} {{'enterprises.employees' | translate}},
        {{enterpriseGroup.enterprisesCount}} {{'enterpriseGroups.enterprisesTitle' | translate}}</span>
    </div>
  </div>
</div>
