<p-dialog
  *ngIf='popupVisible'
  (blur)='popupVisible = false'
  (onHide)='closePopup()'
  [(visible)]='popupVisible'
  [closable]='false'
  [draggable]='false'
  [modal]='true'
  [resizable]='false'
  [style]="{ width: '40rem' }"
  appendTo='body'
  header="{{
    (transition?.id ? 'persons.transitions.editTransition' : 'persons.transitions.new') | translate
  }}"
>

  <div *ngIf='transition' [formGroup]='formGroup'>
    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='true'
                                      formField='employmentId'
                                      fieldKey='persons.transitions.employment'
    >
      <span *ngIf='transition?.transitionStatusId !== TRANSITION_STATE.DRAFT'>
        {{employment().branchAlimentoId}} - {{ employment().branchName }}
      </span>
      <p-dropdown
        *ngIf='transition?.transitionStatusId === TRANSITION_STATE.DRAFT'
        [options]='employments()'
        [showClear]='true'
        appendTo='body'
        formControlName='employmentId'
        id='employmentId'
        optionLabel='label'
        optionValue='employmentId'
        placeholder="{{ 'selectPlaceholder' | translate }}"
        styleClass='w-full'
      >
        <ng-template let-employment pTemplate='selectedItem'>
          <div *ngIf='employment'>
            <span>{{employment.branchAlimentoId}} - {{ employment.branchName }}</span>
          </div>
        </ng-template>
        <ng-template let-employment pTemplate='item'>
          <div *ngIf='employment' class='flex justify-between gap-8 p-1'>
            <div class='flex flex-col'>
              <div class='flex items-center gap-2'>
                <span class='font-bold'>{{employment.branchAlimentoId}} - {{ employment.branchName }}</span>
                <span *ngIf='employment.function'> - {{ employment.function }}</span>
                <span *ngIf='employment.isMainEmployment' class='font-material ml-2'>star</span>
              </div>
              <span>{{employment.branchAddress}}</span>
            </div>
            <div class='flex flex-col'>
              <span>{{'persons.transitions.startDate' | translate}}: {{employment.startDate | date:"dd/MM/yyyy"}}</span>
              <span>{{'persons.transitions.endDate' | translate}}: {{employment.endDate | date:"dd/MM/yyyy"}}</span>
            </div>
          </div>
        </ng-template>
      </p-dropdown>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='true'
                                      formField='typeId'
                                      fieldKey='persons.transitions.type'
    >
      <span *ngIf='transition?.transitionStatusId !== TRANSITION_STATE.DRAFT'>
        {{typeReadOnly}}
      </span>
      <p-treeSelect
        *ngIf='transition?.transitionStatusId === TRANSITION_STATE.DRAFT'
        [options]='$any(transitionTypes)'
        [filter]='true'
        [showClear]='true'
        [resetFilterOnHide]='true'
        (onHide)='closeUnselectedTreeItems()'
        (onClear)='parentTypeId.set(undefined)'
        (onNodeSelect)='onTypeChange($event.node)'
        (onNodeUnselect)='parentTypeId.set(undefined)'
        containerStyleClass='w-full'
        formControlName='typeId'
        id='typeId'
        panelClass='fullWidth'
        appendTo='body'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
      >
        <ng-template let-node pTemplate='value'>
          <span *ngIf='node else emptyValue'>
            {{ node?.label }}
          </span>
          <ng-template #emptyValue>{{ 'trainings.basicData.chooseOption' | translate }}</ng-template>
        </ng-template>
      </p-treeSelect>
    </alimento-ipv-frontend-form-field>

    <div class='w-[202px]'>
      <alimento-ipv-frontend-form-field
        *ngIf='formGroup.get("startDate").enabled'
        [formGroup]='formGroup'
        [required]='true'
        [errorTemplate]='dateErrorTemplate'
        formField='startDate'
        fieldKey='persons.transitions.startDate'
      >
        <span *ngIf='transition?.transitionStatusId !== TRANSITION_STATE.DRAFT'>
          {{transition.startDate | date:'dd/MM/yyyy'}}
        </span>
        <lib-date-picker
          *ngIf='transition?.transitionStatusId === TRANSITION_STATE.DRAFT'
          formControlName="startDate"
          id="startDate"
        ></lib-date-picker>

        <ng-template #dateErrorTemplate>
          <small *ngIf='formGroup.get("startDate")?.hasError("required")'>
            {{ 'trainings.sessions.dateRequired' | translate }}
          </small>
          <small *ngIf='formGroup.get("startDate").hasError("invalidDate")'>
            {{ 'validation.invalidDate' | translate }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>
    <div class='w-[202px]'>
      <alimento-ipv-frontend-form-field
        *ngIf='formGroup.get("endDate").enabled'
        [formGroup]='formGroup'
        [required]='true'
        [errorTemplate]='dateErrorTemplate2'
        formField='endDate'
        fieldKey='persons.transitions.endDate'
      >
        <span *ngIf='transition?.transitionStatusId !== TRANSITION_STATE.DRAFT'>
          {{transition.endDate | date:'dd/MM/yyyy'}}
        </span>
        <lib-date-picker
          *ngIf='transition?.transitionStatusId === TRANSITION_STATE.DRAFT'
          [showIcon]="true"
          [showOnFocus]='false'
          [keepInvalid]='true'
          formControlName="endDate"
          id="endDate"
        ></lib-date-picker>

        <ng-template #dateErrorTemplate2>
          <small *ngIf='formGroup.get("endDate")?.hasError("required")'>
            {{ 'trainings.sessions.dateRequired' | translate }}
          </small>
          <small *ngIf='formGroup.get("endDate").hasError("invalidDate")'>
            {{ 'validation.invalidDate' | translate }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>

    <div class='w-[202px]'>
      <alimento-ipv-frontend-form-field
        *ngIf='formGroup.get("dateOfBirth").enabled'
        [formGroup]='formGroup'
        [required]='true'
        [errorTemplate]='dateErrorTemplate'
        formField='dateOfBirth'
        fieldKey='persons.transitions.dateOfBirth'
      >
        <span *ngIf='transition?.transitionStatusId !== TRANSITION_STATE.DRAFT'>
          {{transition.dateOfBirth | date:'dd/MM/yyyy'}}
        </span>
        <lib-date-picker
          *ngIf='transition?.transitionStatusId === TRANSITION_STATE.DRAFT'
          [showIcon]="true"
          [showOnFocus]='false'
          [keepInvalid]='true'
          formControlName="dateOfBirth"
          id="dateOfBirth"
        ></lib-date-picker>

        <ng-template #dateErrorTemplate>
          <small *ngIf='formGroup.get("dateOfBirth")?.hasError("required")'>
            {{ 'trainings.sessions.dateRequired' | translate }}
          </small>
          <small *ngIf='formGroup.get("dateOfBirth").hasError("invalidDate")'>
            {{ 'validation.invalidDate' | translate }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>

    <alimento-ipv-frontend-form-field *ngIf='formGroup.get("rubricIds").enabled'
                                      [formGroup]='formGroup'
                                      [required]='true'
                                      formField='rubricIds'
                                      fieldKey='persons.transitions.rubric'
    >
      <p-multiSelect
        *ngIf='rubrics$ | async as rubrics'
        [filter]='true'
        [options]='rubrics'
        [showClear]='true'
        appendTo='body'
        formControlName='rubricIds'
        id='rubricIds'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'selectPlaceholder' | translate }}"
        styleClass='w-full'
      ></p-multiSelect>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      *ngIf='formGroup.get("function").enabled'
      [formGroup]='formGroup'
      [required]='true'
      fieldKey='persons.transitions.function'
      formField='function'
    >
      <span *ngIf='transition?.transitionStatusId !== TRANSITION_STATE.DRAFT'>
        {{transition.function}}
      </span>
      <input *ngIf='transition?.transitionStatusId === TRANSITION_STATE.DRAFT'
             class='w-full'
             formControlName='function'
             id='function'
             pInputText
             type='text'
      />
    </alimento-ipv-frontend-form-field>

<!--    <alimento-ipv-frontend-form-field [formGroup]='formGroup'-->
<!--                                      [required]='true'-->
<!--                                      [errorTemplate]='totalBudgetErrorTemplate'-->
<!--                                      formField='totalBudget'-->
<!--                                      fieldKey='persons.transitions.totalBudget'-->
<!--    >-->
<!--      <p-inputNumber-->
<!--        [max]='9999'-->
<!--        [min]='0'-->
<!--        [required]='true'-->
<!--        currency='EUR'-->
<!--        formControlName='totalBudget'-->
<!--        id='totalBudget'-->
<!--        inputStyleClass='w-36'-->
<!--        locale='nl-BE'-->
<!--        mode='currency'-->
<!--      ></p-inputNumber>-->

<!--      <ng-template #totalBudgetErrorTemplate>-->
<!--        <small *ngIf="formGroup.get('totalBudget').hasError('required')">-->
<!--          {{ 'validation.isRequired' | translate : { field: 'persons.transitions.totalBudget' | translate } }}-->
<!--        </small>-->
<!--        <small *ngIf="formGroup.get('totalBudget').hasError('min')">-->
<!--          {{ 'validation.minValue' | translate : { number: 0 } }}-->
<!--        </small>-->
<!--        <small *ngIf="formGroup.get('totalBudget').hasError('max')">-->
<!--          {{ 'validation.maxValue' | translate : { number: 10000 } }}-->
<!--        </small>-->
<!--      </ng-template>-->
<!--    </alimento-ipv-frontend-form-field>-->
  </div>

  <ng-template pTemplate='footer'>
    <div class='flex'>
      <p-button
        *ngIf='transition'
        [loading]='loading'
        (onClick)='addOrUpdateTransition()'
        icon='pi pi-save'
        iconPos='left'
        label="{{ ( transition.id ? 'persons.transitions.actions.edit' : 'persons.transitions.actions.add') | translate }}"
      ></p-button>
      <p-button
        *ngIf='transition && transition.transitionStatusId === TRANSITION_STATE.DRAFT'
        [loading]='loading'
        (onClick)='activateTransition()'
        icon='pi pi-save'
        iconPos='left'
        label="{{ 'persons.transitions.actions.activate' | translate }}"
      ></p-button>
      <p-button
        (onClick)='closePopup()'
        icon='pi pi-times'
        iconPos='left'
        label="{{ 'persons.transitions.actions.cancel' | translate }}"
        styleClass='inverted-button'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
