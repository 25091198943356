<alimento-ipv-frontend-purchase-entry-create-dialog #createDialog
                                                    [branches]='branches'
                                                    (submitPopup)='executeCreatePurchaseEntries($event, createDialog)'
></alimento-ipv-frontend-purchase-entry-create-dialog>

<alimento-ipv-frontend-approve-sales-invoice-dialog [salesInvoice]='currentSalesInvoice'
                                                    (approveSalesInvoice)='executeApproveSalesInvoice($event)'
>
</alimento-ipv-frontend-approve-sales-invoice-dialog>

<alimento-ipv-frontend-sales-invoice-popup (formSubmit)='salesInvoiceUpdate($event)'
                                           (changeSelectedInvoiceIndex)='changeSelectedInvoiceIndex($event)'
></alimento-ipv-frontend-sales-invoice-popup>

<alimento-ipv-frontend-header title="{{ 'trainings.invoices.menuTitle' | translate }}">
  <div class='flex gap-2'>
    <p-button
      *ngIf="showCreateSalesInvoicesButton"
      [loading]='addSalesInvoiceLoading'
      (onClick)='createSalesInvoices()'
      label="{{ 'trainings.salesInvoices.create' | translate | uppercase }}"
    ></p-button>

    <p-button
      *ngIf="showCreatePurchaseEntriesButton"
      (onClick)='createPurchaseEntries(createDialog)'
      label="{{ 'trainings.purchaseEntries.create' | translate | uppercase }}"
    ></p-button>
  </div>
</alimento-ipv-frontend-header>

<p-tabView>
  <p-tabPanel header='{{"trainings.salesInvoices.menuTitle" | translate }} ({{salesInvoices()?.length}})'>
    <div *ngIf="salesInvoices()" class='mt-2'>
      <div class='flex flex-col'>
        <div *ngFor='let salesInvoice of salesInvoices(); let index = index' class='border-b border-b-grey-1 last:border-none'>
          <alimento-ipv-frontend-sales-invoice-card
            [salesInvoice]='salesInvoice'
            [readOnly]='readOnly'
            (actionClicked)='onSaleInvoiceActionClicked(index, salesInvoice, $event)'
          ></alimento-ipv-frontend-sales-invoice-card>
        </div>
      </div>

      <div *ngIf='salesInvoices().length === 0'>{{ 'trainings.salesInvoices.noSalesInvoices' | translate }}</div>
    </div>

  </p-tabPanel>
  <p-tabPanel header='{{"trainings.purchaseEntries.menuTitle" | translate}}  ({{purchaseEntries()?.length}})'>
    <div *ngIf='purchaseEntries()' class='mt-2'>
      <div class='flex flex-col'>
        <div *ngFor='let purchaseEntry of purchaseEntries()' class='border-b border-b-grey-1 last:border-none'>
          <alimento-ipv-frontend-purchase-entry-card
            [purchaseEntry]='purchaseEntry'
            [readOnly]='readOnly'
          ></alimento-ipv-frontend-purchase-entry-card>
        </div>
      </div>

      <div *ngIf='purchaseEntries().length === 0'>{{ 'trainings.purchaseEntries.noPurchaseEntries' | translate }}</div>
    </div>
  </p-tabPanel>
</p-tabView>
