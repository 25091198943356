<div
  class="flex w-full gap-2 px-5 py-3 text-lg cursor-pointer
  {{ !!enrollment ? 'border-b border-b-grey-2 hover:bg-primary-hover' : '' }}"
  (click)='this.showExtraInfo = !this.showExtraInfo; $event.stopImmediatePropagation();'
>
  <div class='w-4 h-4'>
    <div
      (keyup.enter)='this.showExtraInfo = !this.showExtraInfo'
      class='flex items-center py-2 text-primary outline-primary'
      tabindex='0'
    >
      <i class="pi pi-angle-{{ showExtraInfo ? 'up' : 'down' }}"></i>
    </div>
  </div>
  <div class='flex-1' isEllipsis pTooltip>
    <span *ngIf='enrollment'>{{ (enrollment.lastName || '') + ' ' + (enrollment.firstName || '') }}</span>
    <span *ngIf='!enrollment' class='font-bold'>{{ 'enrollments.name' | translate }}</span>
  </div>
  <div class='flex-1' isEllipsis pTooltip>
    <span *ngIf='enrollment'>{{ enrollment.branch?.label }}</span>
    <span *ngIf='!enrollment' class='font-bold'>{{ 'enrollments.company' | translate }}</span>
  </div>
  <div class='basis-[240px]' isEllipsis pTooltip>
    <div *ngIf='enrollment' class='flex items-center gap-2'>
      <span>{{ enrollment.workStatus?.label }}</span>
      <i *ngIf='enrollment.via === CEVORA'
         pTooltip='{{"enrollments.cevora" | translate}}'
         class='bg-alimento-green bg-opacity-30 px-1 rounded not-italic font-bold'>
        C
      </i>
    </div>
    <span *ngIf='!enrollment' class='font-bold'>{{ 'enrollments.statut' | translate }}</span>
  </div>
  <div class='basis-[150px]' isEllipsis pTooltip>
    <span *ngIf='enrollment'>{{ enrollment.subscribed | date : 'dd/MM/yyyy' }}</span>
    <span *ngIf='!enrollment' class='font-bold'>{{ 'enrollments.date' | translate }}</span>
  </div>
  <div class='basis-[40px] flex flex-col items-end'>
    <div *ngIf='enrollment'>
      <alimento-ipv-frontend-more-actions [actions]='actions'></alimento-ipv-frontend-more-actions>
    </div>
  </div>
</div>

<div *ngIf='showExtraInfo && enrollment' class='flex flex-col gap-2 w-full conditional-fields !pb-4'>
  <div class='grid grid-cols-4 gap-2 w-full px-4'>
    <alimento-ipv-frontend-metadata-item
      [title]='"persons.idNoOrDateOfBirth"'
      [value]='enrollment.nationalIdentificationNumber ? (enrollment.nationalIdentificationNumber | insz) :
      (enrollment.birthDate | date:"dd/MM/yyyy")'
    ></alimento-ipv-frontend-metadata-item>

    <alimento-ipv-frontend-metadata-item
      [title]='"enrollments.email"'
      [value]='enrollment.email'
    ></alimento-ipv-frontend-metadata-item>

    <alimento-ipv-frontend-metadata-item
      [title]='"enrollments.phoneNumber"'
      [value]='enrollment.phoneNumber'
    ></alimento-ipv-frontend-metadata-item>

    <alimento-ipv-frontend-metadata-item
      *ngIf='hasContactPerson'
      [title]='"enrollments.contactPerson"'
    >
      <div class='flex flex-wrap gap-1'
           *ngIf='enrollment.contactLastName || enrollment.contactFirstName || enrollment.contactEmail || enrollment.contactPhoneNumber'>
        <span *ngIf='enrollment.contactLastName || enrollment.contactFirstName'>
          {{(enrollment.contactLastName || '') + " " + (enrollment.contactFirstName || '')}}
        </span>
        <span *ngIf='enrollment.contactEmail'>- {{enrollment.contactEmail}}</span>
        <span *ngIf='enrollment.contactPhoneNumber'>- {{enrollment.contactPhoneNumber}}</span>
      </div>
    </alimento-ipv-frontend-metadata-item>

    <alimento-ipv-frontend-metadata-item
      *ngIf='enrollment.interimOfficeName'
      [title]='"enrollments.interimOffice"'
    >
      <div class='flex flex-wrap gap-1'>
        <span>{{enrollment.interimOfficeName}}</span>
        <span>- {{enrollment.interimOfficeContactLastName}} {{enrollment.interimOfficeContactFirstName}}</span>
        <span *ngIf='enrollment.interimOfficeContactEmail'>- {{enrollment.interimOfficeContactEmail}}</span>
      </div>
    </alimento-ipv-frontend-metadata-item>

    <div class='col-span-2'>
      <alimento-ipv-frontend-metadata-item
        *ngIf='enrollment.comments'
        [title]='"enrollments.comments"'
        [value]='enrollment.comments'
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div *ngIf='isCancelled' class='grid grid-cols-4 gap-2 w-full px-4'>
    <alimento-ipv-frontend-metadata-item
      [title]='"enrollments.cancelDialog.reasonCancelled" | translate'
      [value]='reasonCancelled'
    ></alimento-ipv-frontend-metadata-item>
    <div class='col-span-3'>
      <alimento-ipv-frontend-metadata-item
        [title]='"enrollments.cancelDialog.comment" | translate'
        [value]='enrollment?.cancelledComment'
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>
</div>
