import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import {
  APP_CONFIG_TOKEN,
  AppConfig,
  FileHandlerService,
  PaginatedResponse,
  SearchMapper,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import {
  CancelTraining,
  CloseTraining,
  ReactivateTraining, SimpleTraining,
  Training,
  TrainingSearchItem
} from '../types/training.type';
import { getTrainingTypeUrl, TRAINING_TYPE } from '../types/reference-data.enum';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {
  constructor(private http: HttpClient,
              private fileHandler: FileHandlerService,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getSimpleTraining(trainingId: string): Observable<SimpleTraining> {
    return this.http.get<SimpleTraining>(`${this.config.readApiUrl}/trainings/${trainingId}`)
  }

  getTraining(trainingId: string, type: TRAINING_TYPE): Observable<Training> {
    return this.http.get<Training>(`${this.config.readApiUrl}/${getTrainingTypeUrl(type)}/${trainingId}`)
      .pipe(map(result => {
        if (!result.typeId) {
          result.typeId = type;
        }
        if (!result.status && (result as any).statusId) {
          result.status = { data: (result as any).statusId, label: '' };
        }
        return result;
      }));
  }

  createTraining(training: Training, type: TRAINING_TYPE): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/${getTrainingTypeUrl(type)}`, training);
  }

  updateTraining(trainingId: string, training: Training, type: TRAINING_TYPE): Observable<{ id: string }> {
    return this.http.put<{
      id: string
    }>(`${this.config.writeApiUrl}/${getTrainingTypeUrl(type)}/${trainingId}`, training);
  }

  deleteTraining(trainingId: string, type: TRAINING_TYPE): Observable<any> {
    return this.http.delete(`${this.config.writeApiUrl}/${getTrainingTypeUrl(type)}/${trainingId}`);
  }

  cancelTraining(trainingId: string, data: CancelTraining, type: TRAINING_TYPE) {
    return this.http.put(`${this.config.writeApiUrl}/${getTrainingTypeUrl(type)}/${trainingId}/cancel`, data);
  }

  closeTraining(trainingId: string, closeTraining: CloseTraining, type: TRAINING_TYPE) {
    return this.http.put(`${this.config.writeApiUrl}/${getTrainingTypeUrl(type)}/${trainingId}/close`, closeTraining);
  }

  reactivateTraining(trainingId: string, reactivateTraining: ReactivateTraining, type: TRAINING_TYPE) {
    return this.http.put(`${this.config.writeApiUrl}/${getTrainingTypeUrl(type)}/${trainingId}/activate`, reactivateTraining);
  }

  activateTraining(trainingId: string, type: TRAINING_TYPE): Observable<{ id: string }> {
    return this.http.put<{
      id: string
    }>(`${this.config.writeApiUrl}/${getTrainingTypeUrl(type)}/${trainingId}/activate`, {});
  }

  changePublishedState(trainingId: string, published: boolean, type: TRAINING_TYPE): Observable<void> {
    const action = published ? 'publish' : 'unpublish';
    return this.http.put<void>(`${this.config.writeApiUrl}/${getTrainingTypeUrl(type)}/${trainingId}/${action}`, {});
  }

  communicateChanges(trainingId: string, data: any): Observable<void> {
    return this.http.post<void>(`${this.config.writeApiUrl}/opentraining/${trainingId}/sendtrainingmodificationmail`, data);
  }

  sendEnrollmentMails(trainingId: string, data: any): Observable<void> {
    data.trainingId = trainingId;
    return this.http.post<void>(`${this.config.writeApiUrl}/enrollments/sendenrollmentmails`, data);
  }

  requestParticipantsList(trainingId: string, data: any, type: TRAINING_TYPE): Observable<void> {
    return this.fileHandler.downloadFile(`${this.config.writeApiUrl}/${getTrainingTypeUrl(type)}/${trainingId}/participantslist`, data, '');
  }

  sendParticipantsList(trainingId: string, data: any): Observable<void> {
    return this.http.post<void>(`${this.config.writeApiUrl}/opentraining/${trainingId}/sendparticipantslist`, data);
  }

  requestCertificates(trainingId: string, data: any, type: TRAINING_TYPE): Observable<void> {
    return this.fileHandler.downloadFile(`${this.config.writeApiUrl}/${getTrainingTypeUrl(type)}/${trainingId}/certificates`, data, '');
  }

  getTrainingsCount(trainingsParams: SearchRequest): Observable<number> {
    const params = SearchMapper.getParams(trainingsParams);
    return this.http.post<number>(`${this.config.readApiUrl}/trainingsearches/count`, params);
  }

  searchTrainings(requestParams: SearchRequest): Observable<PaginatedResponse<TrainingSearchItem>> {
    const params = SearchMapper.getParams(requestParams);
    return this.http.post<PaginatedResponse<TrainingSearchItem>>(`${this.config.readApiUrl}/trainingsearches`, params);
  }
}
